import React from "react"
import SalaryLoan from "../../../components/body/pages/en-ng/business/salary-loan"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const SalaryLoanPage = () => (
    <Layout>
        <SEO
            canonical={'https://kuda.com/en-ng/business/salary-loan/'}
            title="Salary Loans | Quick and easy Salary Loans with Kuda | Kuda"
            description="Offer your employees salary loans with flexible repayment options at a low interest rate through Kuda. Apply online now to get access and improve your employees' financial wellbeing"
        />
        <SalaryLoan />
    </Layout>

)

export default SalaryLoanPage
