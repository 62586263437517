import React, { useEffect, Fragment } from "react"
import ImageDarkTextRightWithButton from "./components/imageDarkTextRightWithButton"
import ImageDarkTextLeftWithButton from "./components/imageDarkTextLeftWithButton"
import CTABusiness from "../../general/ctaBusiness"
import FAQList from '../../general/faqsList';
import InnerPageHeader from "./components/innerPageHeader";
import { scrollToElement } from "../../../../utility/utils"
import DarkBelowFoldCards from "../../general/darkBelowFoldCards";
import MoreForYouComponent from "../../general/more-for-you";

import EntryBlurIllustration from '../../../../../assets/img/business/blur/salary-loan-hero-blur.svg'
import EntryIllustration from '../../../../../assets/img/salary-loan-heroImg.svg'
// import EntryBlurIllustration from '../../../../../assets/img/salary-loan-heroImg.svg'
import SpendMoneyLightIcon from '../../../../../assets/img/kuda-money-light.inline.svg'
import SendIcon from "../../../../../assets/img/kuda-send-icon.inline.svg"
import KudaPurpleIcon from "../../../../../assets/img/kuda-purple-icon.inline.svg"

import loanAppImage from "../../../../../assets/img/business/salary_loan/loan-application-illustration.svg"
import OfferAcceptedImage from "../../../../../assets/img/business/salary_loan/offer-accepted-illustration.svg"
import SimplifyLoanImage from "../../../../../assets/img/business/salary_loan/simplify-loan-illustration.svg"

import { Img } from "react-image";

import InvoicingIcon from "../../../../../assets/img/more-for-you/kuda-invoicing.inline.svg"
import KudaPOSTerminalIcon from "../../../../../assets/img/more-for-you/kuda-pos-icon.inline.svg"
import VirtualPOSIcon from '../../../../../assets/img/more-for-you/kuda-virtual-icon.inline.svg'

const entryContent = {
  title: (<span>
    Offer your employees <span className="color-secondary">collateral-free</span> loans at competitive rates through Kuda!
  </span>),
  subtitle: "Apply now to get access to Kuda Salary Loan with quick loan application, approval and disbursement for your staff.",
  name: "Apply now",
  url: "/en-ng/business/salary-loan-business-form/",
}

const businessTopFeatures = [
  {
    icon: <SpendMoneyLightIcon />,
    text: "Set up collateral-free salary loans at competitive interest rates easily."
  },
  {
    icon: <SendIcon />,
    text: "Make fast loan applications, approval and disbursement."
  },
  {
    icon: <KudaPurpleIcon />,
    text: "Simplify loan management for your employees through the Kuda app."
  },
]

const Collateral = {
  title: "Set up collateral-free salary loans at competitive interest rates easily.",
  subtitle: "Our interest rates are set to help you support your employees’ financial wellbeing at their convenience.",
  url: "/en-ng/business/salary-loan-business-form/",
  name: "Apply Now",
  illustration: (
    <Img src={loanAppImage} className="kuda-business-image" />

  ),
}

const Staffmorale = {
  title: "Boost staff morale with quick loan applications, approval and disbursement.",
  subtitle: "With our automated loan process, your employees will meet their urgent financial needs in the shortest time possible.",
  url: "/en-ng/business/salary-loan-business-form/",
  name: "Apply Now",
  illustration: (

    <Img src={OfferAcceptedImage} className="kuda-business-image" />

  ),
}

const LoanManagement = {
  title: "Simplify loan management for employees through the Kuda app.",
  subtitle: "Our online loan dashboard will take the stress of managing loans off you and give your employees up-to-date information.",
  url: "/en-ng/business/salary-loan-business-form/",
  name: "Apply Now",
  illustration: (

    <Img src={SimplifyLoanImage} className="kuda-business-image" />

  ),
}


const questions = [
  {
    heading: "What is a salary loan?",
    list: (
      <span className="flex flex-column  mt-2">

        <span className="faq-description f-16">A salary loan is a short-term loan that employees can take based
          on their annual salary. A salary loan usually has a fixed interest rate, doesn’t require collateral
          and can be paid back in flexible installments.</span>

      </span>),
    index: 1
  },
  {
    heading: "How does Kuda Salary Loan work?",
    list: (

      <span className="pt-3 career-message-margin-bottom ">
        <div className="flex flex-column  leading-md-6 f-md-16">
          <div className="mb-3">1. A business applies <a
            href="/en-ng/business/salary-loan-business-form/"
            target="_blank"
            rel="noopener noreferrer"
            className="color-black link-decoration cursor-pointer ml-1"
          >here.</a> to get access to Kuda Salary Loan</div>
          <div className="mb-3">2. An employee of the business can download the Kuda app, open a Kuda account with their BVN and a valid ID, then apply for a loan.</div>
          <div className="mb-3"> 3. Once our quick automated loan approval process is completed, we’ll send the employee a loan offer on their Kuda app.</div>
          <div className="mb-3">4. If the employee accepts our loan offer, we’ll send the money to the employee’s Kuda account in a few minutes.</div>
          <div className="mb-3">5. Loan repayments will be deducted automatically from the employee’s salary by the business.</div>
        </div>
      </span>

    ),
    index: 2
  },
  {
    heading: "How much can your employees borrow?",
    list: (
      <div className="flex flex-column  mt-2">
        <span className="faq-description f-16">Your employees can borrow up to 30% of their annual salary for up to six (6) months.
        </span>

      </div>),
    index: 3
  },
  {
    heading: "How does my business get access to Kuda Salary Loan?",
    list: (
      <div className="flex flex-column  mt-2">
        <span className="faq-description f-16">

          To get access to Kuda Salary Loan, you’ll need to apply <a
            href="/en-ng/business/salary-loan-business-form/"
            target="_blank"
            rel="noopener noreferrer"
            className="color-black link-decoration cursor-pointer ml-1"
          >here.</a> If we accept your application, our support team will guide you through the loan setup process.
        </span>

      </div>),
    index: 4
  },

]


const moreForYou = [

  {
    icon: <KudaPOSTerminalIcon />,
    title: "POS",
    subText: `Buy a physical POS, lease it for a 
    one-time fee, or get a virtual POS to accept payments quickly.`,
    linkTo: `/en-ng/business/pos/`
  },

  {
    icon: <InvoicingIcon />,
    title: "Invoicing",
    subText: `Create and manage invoices easily, and sync your product catalogue for accurate pricing.`,
    linkTo: `/en-ng/business/invoicing/`
  },
  {
    icon: <VirtualPOSIcon />,
    title: "Virtual POS",
    subText: `Empower your salespeople to accept payments instantly at all your business locations.`,
    linkTo: `/en-ng/business/virtual-pos/`
  },

]

const SalaryLoan = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (

    <Fragment>

      <InnerPageHeader
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        buttonName={entryContent.name}
        buttonUrl={entryContent.url}
        entryIllustration={EntryIllustration}
        fallback={<Img src={EntryBlurIllustration} className="hero-illustration" />}
      />
      <DarkBelowFoldCards topFeatures={businessTopFeatures} />
      <ImageDarkTextRightWithButton
        title={Collateral.title}
        subtitle={Collateral.subtitle}
        illustration={Collateral.illustration}
        name={Collateral.name}
        url={Collateral.url}
        isExternal={true}
      />
      <ImageDarkTextLeftWithButton
        title={Staffmorale.title}
        subtitle={Staffmorale.subtitle}
        illustration={Staffmorale.illustration}
        name={Staffmorale.name}
        url={Staffmorale.url}
        isExternal={true}
      />
      <ImageDarkTextRightWithButton
        title={LoanManagement.title}
        subtitle={LoanManagement.subtitle}
        illustration={LoanManagement.illustration}
        name={LoanManagement.name}
        url={LoanManagement.url}
        isExternal={true}
      />

      <FAQList title={"Salary Loan FAQs"} questions={questions} />
      <MoreForYouComponent moreForYou={moreForYou} />
      <CTABusiness />
    </Fragment>
  )
}

export default SalaryLoan

